import React from "react";
import { LDProvider } from "@sindeo/launch-darkly-provider";

import config from "config/app.config";
import { LAUNCH_DARKLY_FLAGS } from "constants/launch-darkly-flags";
import { logger } from "services/logger";

export const LaunchDarklyProvider: React.FC = ({ children }) => (
  <LDProvider
    logger={logger}
    PLATFORM={config.PLATFORM}
    flagsNames={LAUNCH_DARKLY_FLAGS}
  >
    {children}
  </LDProvider>
);
