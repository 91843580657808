import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider as QueryClientProviderBase,
  ReactQueryDevtools,
} from "@sindeo/react-data-query";

export const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      // Use small staleTime instead of default one which is 0.
      // As result, if the same query gets mounted multiple times in one page, the query data won't be re-fetched since instances are being considered as fresh until staleTime is over.
      staleTime: 3 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
};

export const QueryClientProvider: React.FC = ({ children }) => (
  <QueryClientProviderBase client={new QueryClient(QUERY_CLIENT_CONFIG)}>
    {children}
    {process.env.NODE_ENV === "development" && (
      <ReactQueryDevtools position="bottom-right" />
    )}
  </QueryClientProviderBase>
);
