import {
  Logger,
  LogLevel,
  LogTransport,
  SENSITIVE_FIELDS,
} from "@sindeo/logging";

import config from "config/app.config";

const { APP_VERSION, DATADOG_CLIENT_TOKEN, PLATFORM, MASK_SENSITIVE_LOGS } =
  config;

const logger = new Logger({
  clientToken: DATADOG_CLIENT_TOKEN,
  level: LogLevel.DEBUG,
  sensitiveFields: MASK_SENSITIVE_LOGS ? SENSITIVE_FIELDS : [],
  transport:
    process.env.NODE_ENV === "production"
      ? LogTransport.NETWORK
      : LogTransport.CONSOLE,
  tags: {
    app: "lnd-aus",
    bu: "lnd",
    env: PLATFORM === "qa" ? "sbx" : PLATFORM,
    service: "lnd-aus-aus-ruleset-frontend",
    version: APP_VERSION,
  },
});

export { logger };
