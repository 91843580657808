import "reflect-metadata";

import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";

import { App } from "components/App/App";
import { Providers } from "components/Providers/Providers";

import "styles/global.scss";

Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);
