export const enum AppRoute {
  APPROVE_RULESETS = "/approve-rulesets",
  AUTHOR_RULESETS = "/author-rulesets",
  CONSTANTS = "/constants",
  DEPLOYMENTS = "/deployments",
  ERROR = "/error",
  RATE_SHEETS = "/rate-sheets",
  PRICING = "/pricing",
  RULES = "/rules",
  MODELS = "/models",
  VERSIONS = "/versions",
  ROOT = "/",
}
