import React from "react";
import { Loader as BaseLoader } from "@sindeo/react-components";

import { LoaderProps } from "./Loader.interface";

const Loader: React.FC<LoaderProps> = ({
  className,
  size = 32,
  ...restProps
}) => {
  return <BaseLoader {...restProps} wrapperClass={className} size={size} />;
};

export default Loader;
