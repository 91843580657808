import { SunbeamThemeProvider } from "@achieve/sunbeam";

import { combineComponents } from "utils/combineComponents";
import { LaunchDarklyProvider } from "./LaunchDarklyProvider/LaunchDarklyProvider";
import { LocalizationProvider } from "./LocalizationProvider/LocalizationProvider";
import { QueryClientProvider } from "./QueryClientProvider/QueryClientProvider";
import { RouterProvider } from "./RouterProvider/RouterProvider";
import { SnackbarProvider } from "./SnackbarProvider/SnackbarProvider";

const providers = [
  QueryClientProvider,
  RouterProvider,
  SunbeamThemeProvider,
  LocalizationProvider,
  SnackbarProvider,
  LaunchDarklyProvider,
];

export const Providers = combineComponents(providers);
