import { AppConfig } from "types/appConfig";
import appConfigDev from "./app.config.dev.json";
import appConfigPrd from "./app.config.prd.json";
import appConfigQa from "./app.config.qa.json";
import appConfigStg from "./app.config.stg.json";

const windowLocation: string = window.location.hostname;

const config = {
  dev: appConfigDev,
  prd: appConfigPrd,
  qa: appConfigQa,
  stg: appConfigStg,
};

type configNameType = "dev" | "qa" | "stg" | "prd";

let configName: configNameType;

if (windowLocation.indexOf(".dev.") > -1) {
  configName = "dev";
} else if (windowLocation.indexOf(".stg.") > -1) {
  configName = "stg";
} else if (windowLocation.indexOf(".prd.") > -1) {
  configName = "prd";
} else {
  configName = "dev";
}

export default config[configName] as AppConfig;
