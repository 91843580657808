import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import AppLayout from "components/AppLayout/AppLayout.lazy";
import Loader from "components/Loader/Loader";
import { AppRoute } from "constants/appRoute";

export const App: React.FC = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {/* other routes should be placed above the root one */}
      <Route path={AppRoute.ERROR}>Something went wrong</Route>
      <Route path={AppRoute.ROOT}>
        <AppLayout />
      </Route>
    </Switch>
  </Suspense>
);
